Vue.asyncComponent('bp-metadata-selection',{
    props: {
        metadataHandler: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            supportedMetadata: [],
            currentSelectedMetaData: [],
            loading: false
        }
    },
    computed: {
        blueprintId() {
            return this.$route.params.blueprintId;
        },
        /**
         * An array of selected metadata ids
         * @returns {*[]}
         */
        selectedMetadataIds() {
            return this.currentSelectedMetaData.map(metadata => metadata.id);
        }
    },
    methods: {
        /**
         * fetch the supported metadata
         * @returns {Promise<void>}
         */
        async getSupportedMetadata() {
            this.loading = true;
            this.supportedMetadata = await this.metadataHandler.getSupportedMetadata();
            this.loading = false;
        },
        /**
         * Go to metadata form
         * @param id
         */
        goToMetadataForm(metadata) {
            this.$router.push({
                name: 'metadata-selection-form',
                params: {id: metadata.id},
                query: {
                    verticalId: this.$route.query.verticalId,
                    settings: JSON.stringify(metadata.settings),
                }
            });
        },
        /**
         * Check if this metadata item is selected
         * @param metadata
         * @returns {boolean}
         */
        isSelected(metadata) {
            return this.selectedMetadataIds.includes(metadata.id);
        },
        /**
         * Remove the given metadata from the selection list
         * @param metadata
         */
        removeFromSelectedmetadata(metadata) {
            const indexToDelete = this.currentSelectedMetaData.findIndex(selectedmetadata => selectedmetadata.id === metadata.id);
            this.currentSelectedMetaData.splice(indexToDelete, 1);
        },
        /**
         * Add the metadata item to the selection list after save of the form
         * @param metadata
         */
        addMetadataToSelection({id, settings}) {
            // get the full object
            const metadata = this.supportedMetadata.find(metadata => metadata.id === id);
            // ad the new settings
            metadata.settings = settings;

            // push the current selection
            this.currentSelectedMetaData.push(metadata)
        },
        /**
         * Toggle Metadata
         * If the metadata isen't in the selected lists we will open a form and on save we will add it to the selection
         * If the item is already in the selection we will remove it from the selection list
         * @param metadata
         */
        toggleMetadata(metadata) {
            if (this.isSelected(metadata)) {
                this.removeFromSelectedmetadata(metadata);
                return;
            }

            this.goToMetadataForm(metadata);
        },
        /**
         * Notify the parent component we want to apply the current selection
         */
        apply() {
            this.$emit('updateMetaData', this.currentSelectedMetaData);
        },
        /**
         * Close the stacked window by going back
         */
        close() {
            this.$router.push({name: 'blueprint-settings', query: this.$route.query});
        },
    },
    async created() {
        this.getSupportedMetadata();
        const selectedMetaData = await this.metadataHandler.getMetadata();
        // Set the initial selected values and make sure we unbind them from the parent
        this.currentSelectedMetaData = [...selectedMetaData];
    }
}, 'blueprints/meta/bp-metadata-selection.html');