Vue.asyncComponent('bp-blueprint-editor-side-bar', {
    props: {
        showGuides: {
            type: Boolean
        },
        dataProvider: {
            type: Object,
            required: true
        },
        showHiddenElements: {
            type: Boolean,
        },
        breakpoint: {
            type: Object,
        }
    },
    computed: {
        amountOfHiddenElements() {
            if(! this.breakpoint) {
                return null;
            }

            return this.breakpoint.blockPositioning.filter(breakpoint => breakpoint.hidden).length;
        }
    }

}, 'blueprints/editor/bp-blueprint-editor-side-bar.html');