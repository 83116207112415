Vue.asyncComponent('bp-metadata-form',{
    props: {
        metadataHandler: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            loading: false,
            metadata: null,
        }
    },
    computed: {
        /**
         * Blueprint id out of the url
         * @returns {any}
         */
        blueprintId() {
            return this.$route.params.blueprintId;
        },
        /**
         * Meta data id out of the url
         * @returns {*}
         */
        metadataId() {
            return this.$route.params.id;
        },
        /**
         * Get the url for this metadata form
         * @returns {string}
         */
        formUrl() {
            return this.metadataHandler.getMetadataFormUrl(this.metadataId, this.blueprintId, this.$route.query.settings);
        },
        /**
         * Get the url for this metadata form to save
         * @returns {string}
         */
        saveFormUrl() {
            return this.metadataHandler.getSaveMetadataFormUrl(this.metadataId, this.blueprintId);
        },
        /**
         * The current relation string
         * @returns {*}
         */
        relationString() {
            return this.$route.params.relationString;
        }
    },
    methods: {
        /**
         * notify the parent component that we have saved this form
         * and close this form
         */
        save(data) {
            this.$emit('saved', {id: this.metadataId, settings: data.settings});
        },
        /**
         * Close the stacked window by going back
         */
        close() {
            const routeName = this.$route.name === 'metadata-selection-form' ?
                    'metadata-selection':
                    'blueprint-settings';
            this.$router.push({name: routeName, query: {verticalId: this.$route.query.verticalId}});
        },
    }
}, 'blueprints/meta/bp-metadata-form.html');