Vue.asyncComponent('bp-skin-settings-side-bar', {
    data() {
        return {
            loading: false,
            settings: {}
        }
    },
    model: {
        prop: 'settingValues',
        event: 'change'
    },
    props: {
        dataProvider: {
            type: Object,
            default: false
        },
        blueprintId: {
            type: String|Number,
        },
        verticalId: {
            type: String|Number,
        },
        settingValues: {
            type: Object,
            default: () => {}
        }
    },
    computed: {
        /**
         * The current relation string
         * @returns {*}
         */
        relationString() {
            return this.$route.params.relationString;
        },
        /**,
         * Get the url for this settingForm form
         * @returns {string}
         */
        formUrl() {
            return this.dataProvider.getSkinSettingsHandler().getSkinSettingsFormUrl(this.verticalId);
        },
    },
    methods: {
        /**
         * Update current settings
         * @param formValues
         */
        updateSettingValues(formValues) {
            this.settings = formValues;
            this.$emit('change', this.settings);
        },
        /**
         * Notify the parent that we wan't to apply the current settings
         */
        doApply() {
            this.$emit('apply', this.settings);
        }
    },

}, 'blueprints/settings/bp-skin-settings-side-bar.html');