Vue.asyncComponent('bp-settings-form',{
    props: {
        dataProvider: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            showConfirm: false,
            confirmCallback: null,
            alertUnsaved: null,
            unsavedChangesMessage: false,
            activeBlock: null,
        }
    },
    computed: {
        showForm() {
            return this.activeBlock?.settings?.hasForm;
        },
        getFormUrl() {
            return this.dataProvider.getSettingsFormUrl(
                    this.$route.params.blockType,
                    this.$route.query.verticalId,
                    this.$route.query.settings,
                    this.$route.params.hashId
            );
        },
        saveFormUrl() {
            return this.dataProvider.getSaveSettingsFormUrl(
                    this.$route.params.blockType,
                    this.$route.query.verticalId,
                    this.$route.params.hashId
            );
        },
        relationString() {
            return this.$route.params.relationString;
        }
    },
    methods: {
        saveSettings($event) {
            this.$emit('saveSettings', {...$event, referenceId: this.$route.params.referenceId});
            this.close();
        },
        confirmDialog() {
            this.showConfirm = false;
            this.confirmCallback();
        },
        alertUnsavedChanges({showAlert, message}) {
            this.alertUnsaved = showAlert;
            this.unsavedChangesMessage = message;
        },
        attemptClose(close) {
            if (! this.alertUnsaved) {
                close();
                return;
            }

            this.showConfirm = true;
            this.confirmCallback = close;
        },
        close() {
            this.$router.back();
        },
        getBlock(blocks, id) {
            let result = null;

            // get the block by id recursive
            blocks.forEach(block => {
                if(block.id === id) {
                    result = block;
                }

                if(block.blocks.length) {
                    let childResult = this.getBlock(block.blocks, id);
                    if(childResult) {
                        result = childResult;
                    }
                }
            });

            return result;
        },
    },
    async created() {
        let blocks = this.dataProvider.getBlocks();

        if (!blocks.length) {
            await this.dataProvider.fetchBlueprintData();
            blocks = this.dataProvider.getBlocks();
        }

        this.activeBlock = this.getBlock(blocks, this.$route.params.hashId)
    }
}, 'blueprints/form/bp-settings-form.html');