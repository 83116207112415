import ActiveDraggableBlockMixin from '../../mixins/ActiveDraggableBlockMixin.js';
import ActiveDropzoneMixin from '../../mixins/ActiveDropzoneMixin.js';
import EditorEventMixin from '../../mixins/EditorEventMixin.js';
import PreviewHelperMixin from "../../mixins/PreviewHelperMixin";
import BlockHelper from "../../helpers/Block/BlockHelper";

Vue.asyncComponent('bp-block', {
    mixins: [ActiveDraggableBlockMixin, ActiveDropzoneMixin, EditorEventMixin, PreviewHelperMixin],
    data: function() {
        return {
            maxCols: 12,
            showOptions: false,
            showGuides: false,
            style: {
                '--offset': this.offset,
                '--colspan': this.colspan
            },
        }
    },
    props: {
        name: {
            type: String,
        },
        nameDefault: {
            type: String,
            required: true,
        },
        id: {
            required: true,
        },
        type: {
            type: String,
            required: true,
        },
        referenceId: {
            type: Number|String,
            required: true,
        },
        locks: {
            type: Array,
            required: false,
            default: () => {
                return [];
            }
        },
        blocks: {
            type: Array,
            required: false,
            default: () => {
                return [];
            }
        },
        areChildrenAllowed: {
            type: Boolean,
        },
        colspan: {
            type: Number,
            required: true,
        },
        offset: {
            type: Number,
            required: false
        },
        minColspan: {
            type: Number,
        },
        hidden: {
            type: Boolean,
            required: false,
            default: false
        },
        endOfRow: {
            type: Boolean,
            required: false,
            default: false
        },
        fullWidth: {
            type: Boolean,
            required: false,
            default: false
        },
        alignment: {
            type: String,
            required: false,
        },
        previewMode: {
            type:  Boolean,
            required: false,
            default: false
        },
        settings: {
            type: Object,
        },
        showHiddenElements: {
            type:  Boolean,
            required: false,
            default: false
        },
        grid: {
            type: Object,
            required: true,
        },
        showAlignmentOptions: {
            type: Boolean,
            required: false,
            default: false
        },
        dataProvider: {
            type:  Object,
            required: true,
        },
        activeBreakpointId: {
            type:  String,
            required: true,
        },
    },
    computed: {
        /**
         * Check if the current item is active
         * @returns {boolean}
         */
        isActive() {
            return this.activeBlockLocal && this.referenceId === this.activeBlockLocal.referenceId;
        },
        /**
         * Check if the current items dropzone is active
         * @returns {boolean}
         */
        dropzoneActive() {
            return this.getActiveDropzone() === this.referenceId;
        },
        /**
         * Check if we can change the layout of this block
         * For this we will check if we have a lock width type layout or all
         * @returns {boolean}
         */
        canChangeLayout() {
            return this.getLockByAction('layout').length === 0;
        },
        /**
         * Check if we can edit this block
         * For this we will check if we have a lock width type edit or all
         * @returns {boolean}
         */
        canEdit() {
            return this.getLockByAction('edit').length === 0;
        },
        /**
         * Check if we can delete this block
         * For this we will check if we have a lock width type delete or all
         * @returns {boolean}
         */
        canDelete() {
            return this.getLockByAction('delete').length === 0;
        },
        /**
         * Check if we need to show this block
         * @returns {boolean|boolean}
         */
        showBlock() {
            return !this.hidden || (this.hidden && this.showHiddenElements)
        },
        /**
         * We show the option to divide the child blocks equally in a row if we have more then one block
         * @returns {boolean}
         */
        canDivideChildrenEqually() {
            return this.blocks.length > 1;
        },
        /**
         * Block wrapper classes
         * @returns {{"bp-block-wrapper--offset": number, "bp-block-wrapper--has-children": boolean, "bp-block-wrapper--preview": boolean}}
         */
        blockWrapperClasses() {
            return {
                'bp-block-wrapper--has-children': this.areChildrenAllowed,
                'bp-block-wrapper--preview': this.previewMode,
                'bp-block-wrapper--offset': this.offset,
                'bp-block-wrapper--full-width': this.fullWidth,
            }
        },
        /**
         * Block classes
         * @returns {{"bp-block-wrapper--offset": number, "bp-block-wrapper--has-children": boolean, "bp-block-wrapper--preview": boolean}}
         */
        blockClasses() {
            return {
                'bp-block--preview': this.previewMode,
                'bp-block--no-hover': this.previewMode && this.activeBlock,
                'bp-block--dragging': this.isActive,
                'bp-block--full-width': this.fullWidth,
                'bp-block--hidden': this.hidden,
                'bp-block--has-children': this.areChildrenAllowed,
                'bp-block--guides': this.showGuides || this.dropzoneActive,
                'bp-block--active': this.dropzoneActive,
            }
        },
        /**
         * Block header classes
         * @returns {{"bp-block-wrapper--offset": number, "bp-block-wrapper--has-children": boolean, "bp-block-wrapper--preview": boolean}}
         */
        blockHeaderClasses() {
            return {
                'bp-block__header--preview': this.previewMode,
                'bp-block__header--has-children': this.areChildrenAllowed,
            }
        }
    },
    methods: {
        /**
         * When we start draging we keep the block id from the item that is being dragged
         * @param e
         * @param block
         */
        startDrag(e) {
            // check if no other element is dragging
            // this check is needed beceause when you drag an child element de drag e of the parent will be triggerd
            if (e.target !== this.$refs.draggable) {
                return;
            }

            e.dataTransfer.dropEffect = 'move';
            e.dataTransfer.effectAllowed = 'move';

            this.setActiveBlock(this.blockData());
            this.$emit('dragstart', this.blockData());
        },
        endDrag() {
            this.setActiveBlock(null);
            this.$emit('dragend', this.blockData());
        },
        /**
         * The current block is resized or the offset is changed
         * @param $event
         */
        currentBlockDimensionsChanged($event) {
            this.blockDimensionsChanged( {...$event, referenceId: this.referenceId});
        },
        /**
         * A child block dimensions has changed notify the parents
         * @param $event
         */
        blockDimensionsChanged($event) {
            this.$emit('blockDimensionsChanged', $event)
        },
        /**
         * The data of the current block
         * @returns {{colspan: Number, offset: Number, blocks: *[], areChildrenAllowed: Boolean, id: *, name: String}}
         */
        blockData() {
            return {id: this.id, referenceId: this.referenceId, name: this.name, offset: this.offset, colspan: this.colspan, blocks: this.blocks, areChildrenAllowed: this.areChildrenAllowed}
        },
        /**
         * We set the width, Height and possible offfset for the current block
         * For this we will look in the refering element in de preview html
         */
        setDimensionsForPreviewMode() {
            const $el = this.getBlockElementById(this.referenceId);
            this.style =  {
                '--block-height': $el.getBoundingClientRect().height + 'px',
                '--block-width': $el.getBoundingClientRect().width + 'px',
            };

            // afther the width and height is set we can check if there is stille any offset we need to add
            this.$nextTick(() => {
                if ($el.dataset.blockOffset !== undefined) {
                    this.style = {
                        '--block-offset-left': (this.previewIframe.getBoundingClientRect().left + ($el.getBoundingClientRect().left - this.$el.getBoundingClientRect().left)) + 'px',
                        ... this.style
                    };
                }
            })
        },
        /**
         * Notify the parent's that we wan't to delete this block
         */
        deleteBlock() {
            if (! this.canDelete) {
                return;
            }

            this.$emit('deleteBlock', this.referenceId);
        },
        /**
         * Edit the content for this block
         */
        edit() {
            if (! this.canEdit) {
                return;
            }

            this.$router.push({
                name: "blueprint-form",
                params: {
                    blockType: this.type,
                    verticalId: this.$route.query.verticalId,
                    hashId: this.id,
                    referenceId: this.referenceId
                },
                query: {
                    verticalId: this.$route.query.verticalId,
                    settings: JSON.stringify(this.settings),
                }
            })
        },
        /**
         * Notify parent we want to toggle the fullWidth
         */
        toggleFullWidth() {
            this.blockDimensionsChanged({offset: 0, colspan: 12, referenceId: this.referenceId});
            this.blockPositioningChanged({referenceId: this.referenceId, fullWidth: !this.fullWidth});
        },
        /**
         * Notify parent we want to toggle the fullWidth for all breakpoints
         */
        toggleFullWidthAllBreakpoints() {
            this.blockDimensionsChanged({offset: 0, colspan: 12, referenceId: this.referenceId, allBreakpoints: true});
            this.blockPositioningChanged({referenceId: this.referenceId, fullWidth: !this.fullWidth, allBreakpoints: true});
        },
        /**
         * Notify parent we want to toggle the hide
         */
        toggleHide() {
            this.blockPositioningChanged({referenceId: this.referenceId, hidden: !this.hidden});
        },
        /**
         * Notify parent we want to toggle the hide
         */
        toggleEndOfRow() {
            this.blockPropertyChanged({referenceId: this.referenceId, endOfRow: !this.endOfRow});
        },
        /**
         * Set a new custom name for the element
         */
        setNewName(newName) {
            this.$emit('blockPropertyChanged', {referenceId: this.referenceId, name: newName});
        },
        /**
         * Toggle Guides
         */
        toggleGuides() {
            this.showGuides = ! this.showGuides;
        },
        /**
         * Change alignment
         */
        changeAlignment(alignment) {
            switch (alignment) {
                case 'left':
                    this.blockDimensionsChanged({offset: 0, colspan: this.colspan, referenceId: this.referenceId});
                    break;
                case 'center':
                    this.blockDimensionsChanged({
                        offset: Math.ceil((this.grid.numberOfColumns - this.colspan) / 2),
                        colspan: this.colspan,
                        referenceId: this.referenceId
                    });
                    break;
                case 'right':
                    this.blockDimensionsChanged({
                        offset: this.grid.numberOfColumns - this.colspan,
                        colspan: this.colspan,
                        referenceId: this.referenceId
                    });
                    break;
            }
        },
        /**
         * We get the lock by the given action or if a lock has action all
         * @param action
         * @returns {*}
         */
        getLockByAction(action) {
            return this.locks.filter(lock => lock.isLocked && (lock.action.includes(action) || lock.action.includes('all')));
        },
        /**
         * We will divide the child blocks equally in a row or multiple rows depending on the min width
         */
        divideChildrenEqually() {
            const colspans = BlockHelper.divideChildrenEqually(this.blocks, this.maxCols);

            Object.entries(colspans).forEach(([referenceId, colspan]) => {
                console.log(referenceId, colspan);
                this.blockDimensionsChanged({offset: 0, colspan: colspan, referenceId: referenceId});
            })
        }
    },
    mounted() {
        // if we are in preview mode we will need to set ower dimensions
        if (this.previewMode) {
            this.previewIframe.contentWindow.addEventListener('resize', this.setDimensionsForPreviewMode,{capture: true});
            this.previewIframe.addEventListener('load', this.setDimensionsForPreviewMode,{capture: true});
        }
    }
}, 'blueprints/editor/bp-block.html');