export default class FeatureHandler {

    /**
     * Constructor
     * @param features
     * @param callbackMethod
     */
    constructor(features, callbackMethod) {
        this.features = features;
        this.callbackMethod = callbackMethod;
    }

    /**
     * Return all features
     * @returns [*]
     */
    getFeatures() {
        return this.features;
    }

    /**
     * Find a specific feature
     * @param id
     * @returns {*}
     */
    getFeature(id) {
        return this.features.find(feature => feature.id === id);
    }

    /**
     * Check if the feature width the given id is supported for the current blueprint
     * @param id
     * @returns Boolean
     */
    featureSupported(id, ignoreCallback = false) {
        const feature = this.getFeature(id);
        if(! feature) {
            return true;
        }

        // check if the feature is supported for the current blueprint
        if (!feature.supported) {
            // if not we use the callback method width the unsopported message as param
            if (! ignoreCallback) {
                this.callbackMethod(feature?.messageUnsupported);
            }
            return false;
        }

        return true;
    }
}