import FeatureHandler from "./handlers/FeatureHandler";
import MetadataHandler from "./handlers/MetadataHandler";
import HeaderHandler from "./handlers/HeaderHandler";
import SkinSettingsHandler from "./handlers/SkinSettingsHandler";

export default class dataProvider {
    constructor(id, queryParams) {
        this.id = id;
        this.queryParams = queryParams;
        this.vue = window.AppKit.getApp();

        this.getBreakpoints = this.getBreakpoints.bind(this);
        this.getBlocks = this.getBlocks.bind(this);
        this.getBlockTypes = this.getBlockTypes.bind(this);
        this.getVisibility = this.getVisibility.bind(this);
        this.getOriginalBreakpoint = this.getOriginalBreakpoint.bind(this);
        this.fetchBlueprintData = this.fetchBlueprintData.bind(this);
        this.getMetadataHandler = this.getMetadataHandler.bind(this);
        this.getHeaderHandler = this.getHeaderHandler.bind(this);
        this.getSkinSettingsHandler = this.getSkinSettingsHandler.bind(this);
        this.getTitle = this.getTitle.bind(this);
        this.getListUrl = this.getListUrl.bind(this);
        this.fetchInterfaceData = this.fetchInterfaceData.bind(this);
        this.getEventListeners = this.getEventListeners.bind(this);
    }

    /**
     * Get block data
     * @returns {[{blocks: [{blocks: [], id: number, title: string, type: string}, {blocks: [], id: number, title: string, type: string}, {blocks: [], id: number, title: string, type: string}, {blocks: [], id: number, title: string, type: string}], id: number, title: string, type: string}, {blocks: [{blocks: [], id: number, title: string, type: string}], id: number, title: string, type: string}, {blocks: [{blocks: [], id: number, title: string, type: string}], id: number, title: string, type: string}]}
     */
    getBlocks() {
        if(! this.blocks) {
            this.fetchBlueprintData()
        }

        return this.blocks ? this.blocks : [];
    }

    /**
     * Get block data
     * @returns {[{blocks: [{blocks: [], id: number, title: string, type: string}, {blocks: [], id: number, title: string, type: string}, {blocks: [], id: number, title: string, type: string}, {blocks: [], id: number, title: string, type: string}], id: number, title: string, type: string}, {blocks: [{blocks: [], id: number, title: string, type: string}], id: number, title: string, type: string}, {blocks: [{blocks: [], id: number, title: string, type: string}], id: number, title: string, type: string}]}
     */
    getGrid() {
        if(! this.grid) {
            this.fetchBlueprintData()
        }

        return this.grid ? this.grid : [];
    }

    /**
     * Get title
     * @returns {*|null}
     */
    getTitle() {
        if(! this.title) {
            this.fetchBlueprintData()
        }

        return this.title ? this.title : null;
    }

    /**
     * Get visibility
     * @returns {*|{publishedStart: *, publishedEnd: *, published}}
     */
    getVisibility() {
        if(! this.visibility) {
            this.fetchBlueprintData()
        }

        return this.visibility;
    }

    /**
     * Get the possible block types
     * @returns {[{minColspan: number, allowChildren: boolean, icon: string, title: string, type: string}, {minColspan: number, allowChildren: boolean, icon: string, title: string, type: string}, {minColspan: number, allowChildren: boolean, icon: string, title: string, type: string}, {minColspan: number, allowChildren: boolean, icon: string, title: string, type: string}]}
     */
    async getBlockTypes() {
        if(! this.supportedBlocks) {
            this.fetchInterfaceData();
        }

        return this.supportedBlocks;
    }

    /**
     * Get the list url
     * @returns {*}
     */
    async getListUrl() {
        if(!  this.listUrl) {
            await this.fetchInterfaceData();
        }

        return this.listUrl;
    }

    /**
     * Get all breakpoint data
     * @returns {[{isOriginal: boolean, blockPositioning, title: string}, {isOriginal: boolean, blockPositioning, title: string}, {isOriginal: boolean, blockPositioning, title: string}]}
     */
    getBreakpoints() {
        if(! this.breakpoints) {
            this.fetchBlueprintData()
        }

        return this.breakpoints ? this.breakpoints : [];
    }

    /**
     * Get the original breakpoint
     * @returns {{isOriginal: boolean, blockPositioning, title: string}}
     */
    getOriginalBreakpoint() {
        return this.getBreakpoints().find( breakpoint => breakpoint.isOriginal === true);
    }

    /**
     * Generate a new unique referenceID
     */
    getNewReferenceId() {
        return Date.now().toString(36) + Math.random().toString(36).substr(2);
    }

    /**
     * We construct the data for a new block here
     * @param type
     * @returns {{colspan: number, minColspan, offset: number, blocks: *[], areChildrenAllowed, id: *, title: string, titleDefault, requiredParentType: (string|*)}}
     */
    getNewBlockByType(type) {
        return {
            id: null,
            referenceId: this.getNewReferenceId(),
            name: type.name,
            nameDefault: type.nameDefault,
            type: type.type,
            minColspan: type.minColspan,
            colspan: 12,
            offset: 0,
            endOfRow: false,
            areChildrenAllowed: type.areChildrenAllowed,
            requiredParent: type.requiredParent,
            settings: type.settings,
            locks: type.locks,
            conditions: type.conditions,
            blocks: [],

        }
    }

    /**
     * This is the default blockpositioning for a new block
     * @param id
     * @returns {{blockId, colspan: number, orderIndexOriginal: number, fullWidth: boolean, offset: number, orderIndexDiff: number, orderIndex: number, endOfRow: boolean, hidden: boolean}}
     */
    getBlockPositioningForNewBlock(id) {
        return {
            referenceId: id,
            blockId: null,
            orderIndex: null,
            colspan: 12,
            offset: 0,
            hidden: false,
            fullWidth: false,
            alignment: null,
        }
    }

    /**
     * Get the features list
     * @returns {[{name: string, id: string, messageUnsupported: string, supported: boolean},{name: string, id: string, messageUnsupported: string, supported: boolean}]}
     */
    getFeatures() {
        // return [
        //     {
        //         id: 'breakpoints-specific-block-sorting',
        //         name: '',
        //         supported: false,
        //         messageUnsupported: 'Het sorteren van blokken wordt enkel ondersteunt op desktop',
        //     },
        //     {
        //         id: 'add-offset',
        //         name: 'Set Offsets in Breakpoints',
        //         supported: false,
        //         messageUnsupported: 'Toevoegen van offset is niet ondersteunt',
        //     },
        //     // {
        //     //     id: 'block-area-nesting',
        //     //     name: 'Nested areas',
        //     //     supported: false,
        //     //     messageUnsupported: 'Geneste area\'s worden niet ondersteund',
        //     // },
        // ]

        if (! this.features) {
            this.fetchBlueprintData();
        }

        return this.features;
    }

    /**
     * Get the posible breakpoints
     * @returns {[{name: string, description: string, id: string},{name: string, description: string, id: string},{name: string, description: string, id: string},{name: string, description: string, id: string},{name: string, description: string, id: string},null,null]}
     */
    fetchSupportedBreakpoints() {
        if( ! this.supportedBreakpoints) {
            this.fetchInterfaceData();
        }

        return this.supportedBreakpoints;
    }

    /**
     * Get the possible conditions for this breakpoint or this block in breakpoint
     * @param breakpoint
     * @param block
     * @returns {[{actionForm: {bundleId: string, definitionId: string}, name: string, description: string},{actionForm: {bundleId: string, definitionId: string}, name: string, description: string},{actionForm: {bundleId: string, definitionId: string}, name: string, description: string}]}
     */
    fetchConditions(breakpoint, block) {
        return [
            {
                name : "Is Authenticated as CMS user?",
                description : "description here",
                actionForm: {
                    bundleId: "action",
                    definitionId: "form",
                }
            },
            {
                name : "User has press role",
                description : "...",
                actionForm: {
                    bundleId: "action",
                    definitionId: "form",
                }
            },
            {
                name : "Device is mobile",
                description : "...",
                actionForm: {
                    bundleId: "action",
                    definitionId: "form",
                }
            }
        ]
    }

    /**
     * Get all the needed data from the backend for a blueprint
     * @param id
     */
    async fetchBlueprintData() {
        const params = { ...this.queryParams, blueprintId: this.id};
        const url = this.vue.$getSetting('paths.baseBlueprint') + '/blueprint';

        const data = await this.vue.$get(url, params);

        if (! data) {
            return;
        }

        this.title = data.title;
        this.blocks = data.blocks;
        this.visibility = {
            published: data.published,
            publishedStart: data.publishedStart,
            publishedEnd: data.publishedEnd
        };
        this.features = data.features;
        this.grid = data.grid;
        this.vertical = data.vertical;
        this.breakpoints = data.breakpoints;
        this.metadataHandler = (new MetadataHandler(data.metadata));
        this.headerHandler = (new HeaderHandler(data.headers));
    }

    /**
     * Get all the needed data from the backend for the interface
     * @param id
     */
    async fetchInterfaceData() {
        const params = { ...this.queryParams};
        const url = this.vue.$getSetting('paths.baseBlueprint') + '/interface';

        const data = await this.vue.$get(url, params);

        if (!data) {
            return;
        }

        this.supportedBlocks = data.supportedBlocks;
        this.supportedBreakpoints = data.supportedBreakpoints;
        this.listUrl = data.listUrl;
    }

    /**
     * Get the full preview for the current breakpoint
     * @param breakpoint
     */
    async getPreview(breakpoint) {
        const response  = await this.vue.$get('/../preview-html.html')
        return response.html;
    }

    /**
     * Save the current blueprint
     * @param breakpoints
     * @param blocks
     * @returns {boolean}
     */
    async save( breakpoints, blocks, visibility) {
        const params = { ...this.queryParams, blueprintId: this.id};
        const url = this.vue.$getSetting('paths.baseBlueprint') + '/blueprint/post';
        const body = {
            title: this.title,
            metadata: this.metadataHandler.getMetadata(),
            headers: this.headerHandler.getHeaders(),
            breakpoints: breakpoints,
            blocks: blocks,
            published: visibility.published
        };

        const data = await this.vue.$post(url, body, params);

        this.title = data.blueprint.title;
        this.blocks = data.blueprint.blocks;
        this.grid = data.blueprint.grid;
        this.breakpoints = data.blueprint.breakpoints;
        this.visibility = {
            published: data.blueprint.published,
            publishedStart: data.blueprint.publishedStart,
            publishedEnd: data.blueprint.publishedEnd
        };

        this.metadataHandler.setMetadata(data.blueprint.metadata);
        this.headerHandler.setHeaders(data.blueprint.headers);

        return data;
    }

    /**
     * Get the feature handler
     * @param callbackMethod
     * @returns {FeatureHandler}
     */
    getFeatureHandler(callbackMethod) {
        if (! this.featureHandler) {
            this.featureHandler = (new FeatureHandler(this.getFeatures(), callbackMethod));
        }

        return this.featureHandler;
    }

    /**
     * Get the metadata handler for a specific blueprint
     * @returns {Promise<*>}
     */
     async getMetadataHandler() {
        if (! this.metadataHandler) {
            await this.fetchBlueprintData();
        }

        return this.metadataHandler;
    }

    /**
     * Get the skinSettings handler for a specific blueprint
     * @returns {Promise<*>}
     */
    getSkinSettingsHandler() {
        if (! this.skinSettingsHandler) {
            this.skinSettingsHandler = (new SkinSettingsHandler())
        }

        return this.skinSettingsHandler;
    }

    /**
     * Get the header handler for a specific blueprint
     * @returns {Promise<*>}
     */
     async getHeaderHandler() {
        if (! this.headerHandler) {
            await this.fetchBlueprintData();
        }

        return this.headerHandler;
    }

    /**
     * We construct the url to get the form for the settings of a block
     * @param blockType
     * @param verticalId
     * @param settings
     * @param hashId
     * @returns {string}
     */
    getSettingsFormUrl(blockType, verticalId, settings, hashId) {
        const url = new URL(this.vue.$getSetting('paths.baseBlueprint') + '/settings/block/' + blockType + (hashId ? '/' + hashId : '/new'));
        url.searchParams.append('verticalId', verticalId);

        if(settings) {
            url.searchParams.append('settings', settings);
        }

        return url.toString();
    }

    /**
     * We construct the url to save the form for the settings of a block
     * @param blockType
     * @param verticalId
     * @param hashId
     * @returns {string}
     */
    getSaveSettingsFormUrl(blockType, verticalId, hashId) {
        const url = new URL(this.vue.$getSetting('paths.baseBlueprint') + '/settings/block/' + blockType + (hashId ? '/' + hashId : '/new') + '/post') ;
        url.searchParams.append('verticalId', verticalId);

        return url.toString();
    }

    /**
     * Return the defined event listeners
     * @returns {{saveButtonClicked: dataProvider.saveButtonClicked, propertyChanged: dataProvider.propertyChanged, save: (function(*, *): boolean), activeBreakpointsChanged: dataProvider.activeBreakpointsChanged, orderChanged: dataProvider.orderChanged}}
     */
    getEventListeners() {
        return {
            saveButtonClicked: this.saveButtonClicked,
            propertyChanged: this.propertyChanged,
            orderChanged: this.orderChanged,
            activeBreakpointsChanged: this.activeBreakpointsChanged,
            save: this.save.bind(this),
        }
    }

    saveButtonClicked( breakpoints, blocks) {
        //console.log('saveButtonClicked');
    }

    propertyChanged( breakpoints, blocks) {
        //console.log('propertyChanged');
    }

    orderChanged( breakpoints, blocks) {
        //console.log('orderChanged');
    }

    activeBreakpointsChanged(newBreakpointIds) {
        //console.log('activeBreakpointsChanged', newBreakpointIds);
    }
}