Vue.asyncComponent('bp-skin-settings', {
    data() {
        return {
            previewScale: '100%',
            loading: false,
            dataProvider: null,
            breakpoints: [],
            activeBreakpointId: null,
            previewIframe: null,
            settings: [],
            settingsForm: null,
            settingValues: {},
            iframeLoaded: false,
        }
    },
    props: {
        blueprintId: {
            type: String|Number,
        },
        verticalId: {
            type: String|Number,
        },
        queryParams: {
            type: Object,
        },
        dataProviderClass: {
            type: Function,
        }
    },
    computed: {
        /**
         * The current origin of ower preview iframe
         * @returns {string}
         */
        iframeOrigin(){
            return (new URL(this.$refs.iframe[0].src)).origin;
        }
    },
    methods: {
        /**
         * Set the settings for iframe
         * @param settings
         */
        setSettingsForIframe(settings) {
            if (! this.iframeLoaded) {
                return;
            }

            this.settings = settings;
            const data = {
                previewUrl: this.dataProvider.getSkinSettingsHandler().getPreviewUrl(this.verticalId, this.blueprintId),
                settings: this.settings
            }

            this.$refs.iframe[0].contentWindow.postMessage(data, this.iframeOrigin);
        },
        /**
         * Save the form settings
         * @returns {Promise<void>}
         */
        async saveSettings() {
            this.loading = true;
            const url = this.dataProvider.getSkinSettingsHandler().getSaveSkinSettingsUrl(this.verticalId);

            const body = { "formValues": { ...this.settings }};
            const result = await this.$post(url, body);
            this.loading = false;

            if (result.success) {
                this.$handleActions(result.todos, this);
            }
        },
        /**
         * Set the iframe loaded state
         * @param loaded
         */
        setIframeLoaded(loaded) {
            this.iframeLoaded = loaded;
        },
        /**
         * The child iframe can also send us messages
         * for now we only use this to manipulate the loading state
         * @param event
         */
        childIframeListener(event) {
            if (! this.iframeLoaded || event.origin !== this.iframeOrigin) {
                return;
            }

            if(event.data && event.data.loading !== undefined) {
                this.loading = event.data.loading;
            }
        }
    },
    mounted() {
        window.addEventListener('message', this.childIframeListener,false);
    },
    beforeUnmount() {
        window.removeEventListener('message', this.childIframeListener,false);
    },
    async created() {
        this.dataProvider = new this.dataProviderClass(this.blueprintId, {verticalId: this.verticalId});

        await this.dataProvider.fetchInterfaceData();
        this.breakpoints = this.dataProvider.fetchSupportedBreakpoints();
        // set the original breakpoint as the active breakpoint
        this.activeBreakpointId = this.breakpoints.find(breakpoint => breakpoint.isOriginal).id;
    }
}, 'blueprints/bp-skin-settings.html');