const activeDropzoneMixin = {
    data: () => {
        return {
            activeDropzoneLocal: null,
        }
    },
    props: {
        activeDropzone: {
            required: false,
        },
    },
    watch: {
        activeDropzone: function(newVal, oldVal) {
            if(this.activeDropzoneLocal !== newVal) {
                this.activeDropzoneLocal = newVal;
            }
        },
        activeDropzoneLocal: function(newVal, oldVal) {
            this.$emit('setActiveDropzone', newVal);
        }
    },

    methods: {
        setActiveDropzone(e) {
            this.activeDropzoneLocal = e;
            this.$emit('setActiveDropzone', e);
        },
        getActiveDropzone() {
            return this.activeDropzoneLocal;
        }
    },
    created() {
        this.activeDropzoneLocal = this.activeDropzone;
    }
};

export default activeDropzoneMixin;