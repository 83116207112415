import PreviewHelperMixin from "../../../mixins/PreviewHelperMixin";
import ActiveDraggableBlockMixin from "../../../mixins/ActiveDraggableBlockMixin";
import ActiveDropzoneMixin from "../../../mixins/ActiveDropzoneMixin";
import EditorEventMixin from "../../../mixins/EditorEventMixin";

Vue.asyncComponent('bp-preview-editor', {
    mixins: [ActiveDraggableBlockMixin, ActiveDropzoneMixin, EditorEventMixin, PreviewHelperMixin],
    data() {
        return {
            contentTopOffset: null,
            contentBottomOffset: null,
        };
    },
    props: {
        breakpoint: {
            type: Object,
            required: true,
        },
        blocks: {
            type: Array,
            required: true,
        },
        dataProvider: {
            type: Object,
            required: true,
        },
        forceGuides: {
            type: Boolean,
            required: false,
            default: false
        },
        grid: {
            type: Object,
            required: true,
        },
        activeBreakpointId: {
            type: String,
            required: true,
        }
    },
    watch: {
        previewIframe() {
            this.previewIframe.contentWindow.addEventListener('resize', this.setContentHeight);
        }
    },
    methods: {
        /**
         * We set the padding top and bottom for the content wrapper so it's position is correct
         */
        setContentHeight() {
            // we get the wrapper element and calculate the offset's of that element
            const $contentWrapper = this.previewIframe.contentWindow.document.querySelector(`[data-content-wrapper]`);
            this.contentTopOffset = $contentWrapper.getBoundingClientRect().y + 'px';
            this.contentBottomOffset = (this.previewIframe.contentWindow.document.body.scrollHeight - ($contentWrapper.getBoundingClientRect().y + $contentWrapper.getBoundingClientRect().height)) + 'px';
        },
    },
    mounted() {
        if (this.previewIframe) {
            this.previewIframe.contentWindow.addEventListener('resize', this.setContentHeight);
            this.previewIframe.addEventListener('load', this.setContentHeight);
        }
    }
}, 'blueprints/editor/preview/bp-preview-editor.html');