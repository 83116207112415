/**
 * Helper methods for breakpoints
 */
export default class BreakpointHelper {

    /**
     * Determine the width of a breakpoint
     * @param breakpoint
     * @returns string
     */
    static getBreakpointWidth(breakpoint) {
        const mediaQuery = breakpoint.mediaQuery.mediaQuerySubList.find(mediaQuery => mediaQuery.mediaTypes.includes('screen'));
        const feature = mediaQuery.features.find(feature => feature.name === 'max-width');

        return feature.value;
    }
}