export default class MetadataHandler {
    constructor(metadata) {
        this.setMetadata(metadata);
        this.vue = window.AppKit.getApp();
    }

    /**
     * get metadata
     * @returns {*}
     */
    getMetadata() {
        return this.metadata;
    }

    /**
     * Set metadata
     * @param metadata
     */
    setMetadata(metadata) {
        this.metadata = metadata;
    }

    /**
     * Get the meta data form url
     * @param metadataId
     * @param blueprintId
     * @returns {string}
     */
    getMetadataFormUrl(metadataId, blueprintId, settings) {
        const url = new URL(this.vue.$getSetting('paths.baseBlueprint') + '/metadata/form/' + metadataId + '/' + blueprintId);

        if(settings) {
            url.searchParams.append('settings', settings);
        }

        return url.toString();
    }

    /**
     * Get the meta data save form url
     * @param metadataId
     * @param blueprintId
     * @returns {string}
     */
    getSaveMetadataFormUrl(metadataId, blueprintId) {
        const url = new URL(this.vue.$getSetting('paths.baseBlueprint') + '/metadata/form/' + metadataId + '/' + blueprintId + '/post');
        return url.toString();
    }

    /**
     * Get all the supported meta data options for a blueprint
     * @param bluerpintId
     * @returns {Promise<[{name: string, description: string, id: string},{name: string, description: string, id: string},{name: string, description: string, id: string}]>}
     */
    async getSupportedMetadata() {
        const url = await this.vue.$getSetting('paths.baseBlueprint') + '/metadata/supported/';
        return await this.vue.$get(url);
    }
}