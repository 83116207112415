import NumberHelpers from '../../../../spine-appkit-vue/src/utils/helpers/number-helpers';

/**
 * A helper class that wil divide Children equally
 * We will try to provide the best ways to divide the children equally for each row
 */
export default class DivideChildrenHelper {
    /**
     * @param blocks
     * @param maxCols
     * @param maxPerRow
     * @param greedy
     */
    constructor(blocks, maxCols, maxPerRow = 4, greedy = 2) {
        this.blocks = blocks;
        this.maxCols = maxCols;
        this.maxPerRow = maxPerRow;
        this.greedy = greedy;

        this.getRows = this.getRows.bind(this);
        this.getColspans = this.getColspans.bind(this);
    }

    /**
     * This function will return an object width block referenceIds and the calculated colspans
     * @returns {{}}
     */
    getColspans() {
        const rows = this.getRows();

        let colspans = {};
        rows.forEach(row => {
            this.calculateColspansForRow(row, colspans);
        });

        return colspans;
    }

    /**
     * Calculate the rows in which order the blocks wil fit
     * @returns {*[][]}
     */
    getRows() {
        let activeRowIndex = 0;
        let rowWidth = 0;
        const rows = [[]];

        this.blocks.forEach(block => {
            // if no minColspan is given we use 1
            const minColspan = block.minColspan ? block.minColspan : 0;

            // check if the sum of the existing width the min colspan and the greedy factor still fits in one row
            if ((rowWidth + minColspan + this.greedy) > this.maxCols) {
                // if not we add another row for this block;
                rows.push([]);
                // update the active row index
                activeRowIndex++;
                // and reset the row width
                rowWidth = 0
            }

            // we add the colspans
            rowWidth = rowWidth + minColspan;
            const activeRow = rows[activeRowIndex];
            activeRow.push(block);

            // if the row is full we add another row
            if (activeRow.length >= this.maxPerRow) {
                rows.push([]);
                activeRowIndex++;
                rowWidth = 0
            }
        });

        return rows;
    }

    /**
     * Caculate the colspans for each block of the given row
     * @param row
     * @param colspans
     */
    calculateColspansForRow(row, colspans) {
        let sumMinColspans  = 0;
        const blocksWidthMinColspan = row.filter(block => block.minColspan);
        const blankBlocks = row.filter(block => !block.minColspan);
        // we now loop al the blocks to get the sum of all minColspans and count the blanks
        blocksWidthMinColspan.forEach(block => sumMinColspans = sumMinColspans + block.minColspan);

        let colspanForBlanks;
        let spaceToAddToFirstColspanWidthMinColspan;
        const spaceLeft = (this.maxCols - sumMinColspans);
        // if we have blanks
        if(blankBlocks.length) {
            // we calculate the colspan for a blank
            colspanForBlanks = spaceLeft / blankBlocks.length;
        }

        // if we have a float
        if(NumberHelpers.isFloat(colspanForBlanks)) {
            // we need te add 1 colspan to the first block width a min colspan
            colspanForBlanks = Math.floor(colspanForBlanks);
            spaceToAddToFirstColspanWidthMinColspan = spaceLeft - (colspanForBlanks *  blankBlocks.length);
        }

        // if we have blank blocks we set its colspan
        if(blankBlocks.length) {
            blankBlocks.forEach(block => {
                colspans[block.referenceId] = colspanForBlanks;
            });
        }

        // if we dont have any blank blocks in this row and we still
        // have room left in the current row that is bigger then 1 colspan
        let spaceToAdd = 0;
        if( !blankBlocks.length && spaceLeft > 1) {
            // we will grow the current blocks
            spaceToAdd = spaceLeft / blocksWidthMinColspan.length;

            // if the leftover space is unevent
            if(NumberHelpers.isFloat(spaceToAdd)) {
                spaceToAdd = Math.floor(spaceToAdd);
                // we will add an extra colspan to the first element width minColspan
                spaceToAddToFirstColspanWidthMinColspan = 1;
            }
        }

        blocksWidthMinColspan.forEach((block, key) => {
            if(key === 0 && spaceToAddToFirstColspanWidthMinColspan) {
                colspans[block.referenceId] = block.minColspan + spaceToAddToFirstColspanWidthMinColspan + spaceToAdd;
                return;
            }

            colspans[block.referenceId] = block.minColspan + spaceToAdd;
        });
    }
}