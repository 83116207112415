import dataProvider from "./providers/DataProvider";

Vue.blueprintRoutes = [
    {
        path: '/blueprint/:blueprintId',
        name: 'blueprint',
        component: {
            props: {
                dataProviderClass: {
                    type: Function,
                    default: dataProvider
                }
            },
            template: '<bp-blueprint :dataProviderClass="dataProviderClass" :blueprintId="$route.params.blueprintId" :queryParams="$route.query"/>'
        },
        meta: {
            title: "blueprints",
        },
        children: [
            {
                path: 'condition/:breakpointId',
                name: 'blueprint-condition-form',
                components: {
                    childView: {
                        props: ['dataProvider'],
                        template: '<bp-conditions :dataProvider="dataProvider" :breakpointId="$route.params.breakpointId"/>',
                    }
                },
                children: [
                    {
                        path: ':bundle/:definition/:objectId/:relationString?',
                        name: 'blueprint-condition-action-form',
                        meta: {
                            title: "blueprints condition form",
                        },
                        components: {
                            conditionChildview: {
                                props: ['dataProvider'],
                                template: '<bp-settings-form :dataProvider="dataProvider" />'
                            }
                        },
                    }
                ]
            },
            {
                path: 'blueprint-settings/:activeTab',
                name: 'blueprint-settings',
                meta: {
                    title: "Blueprints settings",
                },
                props: true,
                components:{
                    childView:  {
                        props: ['dataProvider'],
                        template: '<bp-blueprint-settings :dataProvider="dataProvider"/>'
                    }
                },
                children: [
                    {
                        path: 'select-meta-data',
                        name: 'metadata-selection',
                        meta: {
                            title: "Blueprints metadata selection",
                        },
                        components: {
                            childView: {
                                props: ['metadataHandler'],
                                template: '<bp-metadata-selection :metadataHandler="metadataHandler" v-bind="$attrs" v-on="$listeners"/>'
                            }
                        },
                        children: [
                            {
                                path: ':id/:relationString?',
                                name: 'metadata-selection-form',
                                meta: {
                                    title: "Blueprints metadata form",
                                    dataProvider: dataProvider
                                },
                                components: {
                                    childView: {
                                        props: ['metadataHandler'],
                                        template: '<bp-metadata-form :metadataHandler="metadataHandler" v-on="$listeners" />'
                                    }
                                },
                            }
                        ]
                    },
                    {
                        path: 'select-headers',
                        name: 'headers-selection',
                        meta: {
                            title: "Blueprints headers selection",
                        },
                        components: {
                            childView: {
                                props: ['headerHandler'],
                                template: '<bp-headers-selection :headerHandler="headerHandler" v-bind="$attrs" v-on="$listeners"/>'
                            }
                        }
                    },
                    {
                        path: ':id/:relationString?',
                        name: 'metadata-form',
                        meta: {
                            title: "Blueprints meta data form",
                        },
                        components: {
                            childView: {
                                props: ['metadataHandler'],
                                template: '<bp-metadata-form :metadataHandler="metadataHandler"  v-on="$listeners"/>'
                            }
                        },
                    }
                ]
            },
            {
                path: ':blockType/:referenceId/:hashId?/:relationString?',
                name: 'blueprint-form',
                meta: {
                    title: "Blueprints form",
                    dataProvider: dataProvider
                },
                props: true,
                components: {
                    childView:  {
                        props: ['dataProvider'],
                        template: '<bp-settings-form :dataProvider="dataProvider" @saveSettings="$emit(\'saveSettings\', $event)"/>'
                    }
                },
            },
        ]
    },
    {
        path: '/skin-settings/:blueprintId/:verticalId/:relationString?',
        name: 'blueprint-skin-settings',
        meta: {
            title: "Blueprints skin settings",
            dataProvider: dataProvider
        },
        props: true,
        component:{
            template: '<bp-skin-settings :dataProviderClass="$route.meta.dataProvider" :blueprintId="$route.params.blueprintId" :verticalId="$route.params.verticalId"></bp-skin-settings>'
        },
        children: [
            {
                path: 'menu',
                name: 'blueprint-skin-settings-menu',
                meta: {
                    title: "Blueprints skin settings menu",
                    dataProvider: dataProvider
                },
                components: {
                    menuView: {
                        props: ['dataProvider'],
                        template: '<cms-navigation-tree v-bind="$attrs" v-on="$listeners"/>',
                    }
                },
                children: [
                    {
                        path: ':menuItemId',
                        name: 'blueprint-skin-settings-menu-item',
                        meta: {
                            title: "Blueprints skin settings menu item",
                            dataProvider: dataProvider
                        },
                        components: {
                            childView: {
                                props: ['dataProvider'],
                                template: '<cms-navigation-tree v-bind="$attrs" v-on="$listeners"/>',
                            }
                        },
                        children: [

                        ]
                    }
                ]
            }
        ]
    }
];


Vue.blueprintRouter = (router) => {
    router.addRoutes(Vue.blueprintRoutes);
}