export default class SkinSettingsHandler {

    constructor() {
        this.vue = window.AppKit.getApp();
    }

    /**
     * Get the skin settings form url
     * @param metadataId
     * @param blueprintId
     * @returns {string}
     */
    getSkinSettingsFormUrl(verticalId) {
        const url = new URL(this.vue.$getSetting('paths.baseBlueprint') + '/skin/settings/' + verticalId);
        return url.toString();
    }

    /**
     * Get the skin settings preview url
     * @param metadataId
     * @param blueprintId
     * @returns {string}
     */
    getPreviewUrl(verticalId, bluerpintId) {
        const url = new URL(this.vue.$getSetting('paths.baseBlueprint') + '/skin/settings/preview/' + verticalId + '/' + bluerpintId);
        return url.toString();
    }

    /**
     * Get the skin settings save form url
     * @param metadataId
     * @param blueprintId
     * @returns {string}
     */
    getSaveSkinSettingsUrl(verticalId) {
        const url = new URL(this.vue.$getSetting('paths.baseBlueprint') + '/skin/settings/' + verticalId + '/post');
        return url.toString();
    }
}