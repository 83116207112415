Vue.asyncComponent('bp-grid-container', {
    data() {
        return {
            style: {},
        }
    },
    props: {
        id: {
            type: Number,
            required: true,
        },
        fullWidth: {
            type: Boolean,
            required: false,
            default: false
        },
        previewMode: {
            type: Boolean,
            required: false,
            default: false
        },
        previewIframe: {
            type: HTMLIFrameElement,
        },
    },
    methods: {
        setDimensionsForPreviewMode() {
            const $el = this.previewIframe.contentWindow.document.querySelector(`[data-block-container="${this.id}"]`);

            this.style =  {
                height: $el.getBoundingClientRect().height + 'px',
                width: $el.getBoundingClientRect().width + 'px'
            };
        }
    },
    created() {
        // if we are in preview mode we will need to set ower dimensions
        if (this.previewMode) {
            this.previewIframe.contentWindow.addEventListener('resize', this.setDimensionsForPreviewMode,{capture: true});
            // this.previewIframe.addEventListener('load', this.setDimensionsForPreviewMode,{capture: true});
        }
    }
}, 'blueprints/editor/grid/bp-grid-container.html');