Vue.asyncComponent('bp-hidden-block-list', {
    props: {
        blocks: {
            type: Array
        }
    },
    methods: {
        showBlock(block) {
            this.$emit('blockPropertyChanged', {blockId: block.id, hidden: !block.hidden})
        }
    }
}, 'blueprints/editor/bp-hidden-block-list.html');