Vue.asyncComponent('bp-block-type-list', {
    data: function() {
        return {
            q: null,
            blockTypes: []
        }
    },
    props: {
        dataProvider: {
            type: Object,
            required: true
        }
    },
    computed: {
        activeBlockTypes() {
            if (!this.q) {
                return this.blockTypes;
            }

            return this.blockTypes.filter(blockType => blockType.name.toLowerCase().includes(this.q.toLowerCase()));
        }
    },
    methods: {
        setActiveBlockType(newBlock) {
            this.$emit('activeBlockTypeChanged', newBlock);
        },
        addNewBlock(type) {
            const newBlock = this.dataProvider.getNewBlockByType(type);
            this.$emit('addNewBlock', newBlock);
            this.setActiveBlockType(newBlock);
        },
        childDragStart(type) {
            this.addNewBlock(type);
        },
        childDragStop() {
            this.setActiveBlockType(null);
        }
    },
    async created() {
        this.loading = true;
        this.blockTypes = await this.dataProvider.getBlockTypes();
        this.loading = false;
    }
}, 'blueprints/editor/bp-block-type-list.html');