Vue.asyncComponent('bp-conditions', {
    data() {
        return {
            loading: false,
            conditions: [],
            breakpoint: null,
            activeConditions: [],
        }
    },
    props: {
        breakpointId: {
            type: String,
            required: true
        },
        block: {
            type: Object,
        },
        dataProvider: {
            type: Object,
            required: true
        }
    },
    methods: {
        hideForm() {
            this.$router.push({name: 'blueprint'});
        },
        submit() {
            console.log('submit');
        },
        addAction(condition) {
            this.$router.push({
                name: 'blueprint-condition-action-form' , params: {
                breakpointId: this.breakpointId,
                bundle: condition.actionForm.bundleId,
                definition: condition.actionForm.definitionId,
                objectId: 'new',
            }});
        }
    },
    created() {
        // get the conditions for the current breakpoint
        this.conditions = this.dataProvider.fetchConditions(this.breakpoint, this.block);
        // get the curren breakpoint for display purposes
        this.breakpoint = this.dataProvider.getBreakpoints().find(breakpoint => breakpoint.id === this.breakpointId);
    }
}, 'blueprints/editor/bp-conditions.html');