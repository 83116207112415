Vue.asyncComponent('bp-headers-selection',{
    props: {
        headerHandler: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            supportedHeaders: [],
            selectedHeaderIds: [],
            loading: false
        }
    },
    computed: {
        blueprintId() {
            return this.$route.params.blueprintId;
        }
    },
    methods: {
        /**
         * fetch the supported headers
         * @returns {Promise<void>}
         */
        async getSupportedHeaders() {
            this.loading = true;
            this.supportedHeaders = await this.headerHandler.getSupportedHeaders();
            this.loading = false;
        },
        /**
         * Check if this headers item is selected
         * @param headers
         * @returns {boolean}
         */
        isSelected(headers) {
            return this.selectedHeaderIds.includes(headers.id);
        },
        /**
         * Notify the parent component we want to apply the current selection
         */
        apply() {
            const out = this.supportedHeaders.filter(header => this.selectedHeaderIds.includes(header.id));
            this.$emit('updateHeaders', out);
        },
        /**
         * Close the stacked window by going back
         */
        close() {
            this.$router.push({name: 'blueprint-settings', query: this.$route.query});
        },
    },
    async created() {
        this.getSupportedHeaders();
        const selectedHeaders = await this.headerHandler.getHeaders();
        // Set the initial selected values and make sure we unbind them from the parent
        this.selectedHeaderIds = selectedHeaders.map(header => header.id);
    }
}, 'blueprints/headers/bp-headers-selection.html');