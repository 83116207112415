import BlueprintDataTranslator from '../helpers/BlueprintDataTranslator';
import BreakpointHelper from "../helpers/BreakpointHelper";
import BlockHelper from "../helpers/Block/BlockHelper";

Vue.asyncComponent('bp-blueprint', {
    data: () => {
        return {
            loading: true,
            dataProvider: null,
            grid: null,
            breakpoints: [],
            blocks: [],
            visibility: null,
            originalBreakpoint: null,
            activeBreakpointId: null,
            dragActive: false,
            activeBlock: null,
            activeDropzone: null,
            showBreakpointDropdown: false,
            showDropdownBlockTypes: false,
            previewMode: false,
            showGuides: null,
            showHiddenElements: false,
            tempParentBlock: null, // this variable is used for when a block is dragged with a required parent
            featureModalOpen: false,
            featureMessage: null,
            blueprintDataStartState: null
        }
    },
    props: {
        viewMode: {
            type: String,
        },
        blueprintId: {
            type: String|Number,
        },
        queryParams: {
            type: Object,
        },
        dataProviderClass: {
            type: Function,
        }
    },
    watch: {
        activeBlock() {
            // when we stop dragging we reset the temp parent block
            if ( ! this.activeBlock) {
                this.tempParentBlock = null;
                this.activeDropzone = null;
            }
        }
    },
    computed: {
        /**
         * Transform the breakpoints (width block positions) and blocks to a three structure
         * @returns {*[]}
         */
        breakpointThreeStructure() {
            return (new BlueprintDataTranslator(this.breakpoints, this.blocks)).translate();
        },
        /**
         * Return the breakpoint that we are editing now
         * @returns {*}
         */
        activeBreakpoint() {
            return this.breakpoints.find(breakpoint => breakpoint.id === this.activeBreakpointId);
        },
        /**
         * Return the blueprint data object
         * @returns {{blueprint: computed.blueprint, headers: *, metadata: *, blocks: [], title}}
         */
        blueprintData() {
            return {
                title: this.dataProvider.title,
                breakpoints: this.breakpoints,
                blocks: this.blocks,
                metadata: this.dataProvider.metadataHandler.getMetadata(),
                headers: this.dataProvider.headerHandler.getHeaders(),
            }
        },
        /**
         * The current verticalId
         * @returns {*}
         */
        verticalId() {
            return this.queryParams.verticalId;
        }
    },
    methods: {
        /**
         * add a  breakpoint
         * @param breakpointToAdd
         */
        addBreakpoint(breakpointToAdd, index) {
            breakpointToAdd.blockPositioning = this.originalBreakpoint.blockPositioning;
            this.breakpoints.splice(index,0,breakpointToAdd)
        },
        /**
         * remave a breakpoint
         * @param breakpointToRemove
         */
        removeBreakpoint(breakpointToRemove) {
            const indexToRemove = this.breakpoints.findIndex(breakpoint => breakpoint.id === breakpointToRemove.id);
            this.breakpoints.splice(indexToRemove, 1);
        },
        /**
         * Activate a dropzone
         * @param e
         */
        setActiveDropzone(e) {
            this.activeDropzone = e;
        },
        /**
         * The active block type has changes
         * @param type
         */
        activeBlockTypeChanged(block) {
            // whe, we reset the active block type don't show dropdown any more
            if (! block) {
                this.showDropdownBlockTypes = false;
            }

            this.activeBlock = block;
        },
        /**
         * Add a new block to the current blocks array
         * @param type
         */
        addNewBlock(newBlock) {
            //first we add the new block type to the (active)blocks
            this.blocks.push(newBlock);

            // now we need to add a position element for the new block in each breakpoint
            this.breakpoints.forEach((breakpoint) => {
                breakpoint.blockPositioning.push(this.dataProvider.getBlockPositioningForNewBlock(newBlock.referenceId))
            });
        },
        /**
         * delete a block in the tree structure
         * @param blocks
         * @param id
         */
        deleteBlock(blocks, referenceId) {
            for (let i = 0; i < blocks.length; i += 1) {
                if (blocks[i].referenceId === referenceId) {
                    blocks.splice(i, 1);
                } else {
                    this.deleteBlock(blocks[i].blocks, referenceId)
                }
            }
        },
        /**
         * Set the parent for the current block and change the orderIndex for the block in the current breakpoint
         * @param parentReferenceId
         * @param orderIndex
         * @param referenceId
         * @param breakpointId
         */
        async blockParentChanged({parentReferenceId, orderIndex, referenceId}, breakpointId) {
            // find the breakpoint where we need to do the position changes
            const breakpoint = this.breakpoints.find(breakpoint => breakpoint.id === breakpointId);

            // check if we support changing order index for different breakpoints
            if (! breakpoint.isOriginal && !this.featureHandler.featureSupported('breakpoints-specific-order')) {
                return;
            }

            // make a copy of the needed block without any reference
            const block = {...BlockHelper.getBlock(this.blocks, referenceId)};
            let parent = this;
            if (parentReferenceId !== undefined) {
                parent = BlockHelper.getBlock(this.blocks, parentReferenceId);
            }

            if((block.type === 'block-area' || block.type === 'block-legacy-area') && this.checkInArea(parent) && !this.featureHandler.featureSupported('block-area-nesting')) {
                return;
            }
            // When we have a temp parent but the block has changed to an existing parent we will need to remove the temp parent
            if(block.requiredParent && (parent.type === block.requiredParent) && this.tempParentBlock && parent !== this.tempParentBlock) {
                // get the parent of the tempParent
                let oldParentOfTemp = BlockHelper.getParentBlock(this, this.tempParentBlock.referenceId);

                // delete the temp parent
                this.deleteBlock(this.blocks, this.tempParentBlock.referenceId);

                // get the oldParentOfTemp blocks sorted by there blockPosition
                const oldParentOfTempSortBlocks = this.getBlocksSortedByBlockPosition(oldParentOfTemp.blocks, breakpointId);

                // set all the old blocks there new position
                oldParentOfTempSortBlocks.forEach((oldParentOfTempSortBlock, order) => {
                    this.setOrderIndexForBlock( order, oldParentOfTempSortBlock, breakpoint);
                });

                this.tempParentBlock = null;
            }

            // If we have a requiredParent and it doesn't match we need to wrap the element in it first
            // also if there is already a temporary parent block we will move that element instead
            if (block.requiredParent && (parent === this || parent.type !== block.requiredParent) && !this.tempParentBlock) {
                // create the requiredParent
                const newParentBlock = await this.getRequiredParentBlock(block.requiredParent);
                // we set it as the temperary parent until we drop the element
                this.tempParentBlock = newParentBlock;
                // add the new parent
                this.addNewBlock(newParentBlock);
            }

            // when there is a temporary parent we need to move the parent the order index that is given and add the block in it
            if (block.requiredParent && (parent === this || parent.type !== block.requiredParent) && this.tempParentBlock) {
                // delete the block from the old parent
                this.deleteBlock(this.blocks, this.tempParentBlock.referenceId);
                const sortBlocksTempParent = this.getBlocksSortedByBlockPosition(parent.blocks, breakpointId);
                sortBlocksTempParent.splice(orderIndex, 0, this.tempParentBlock);
                parent.blocks.splice(orderIndex, 0, this.tempParentBlock);
                // now we need to update the order index of all the sibling blocks
                sortBlocksTempParent.forEach((siblingBlocks, order) => {
                    this.setOrderIndexForBlock( order, siblingBlocks, breakpoint);
                });

                orderIndex = 0;
                parent = this.tempParentBlock;
            }

            // because we move the element from another one we will need to update the old parent order indexes ass well
            let oldParent = BlockHelper.getParentBlock(this, referenceId);
            // delete the block from the old parent
            this.deleteBlock(this.blocks, referenceId);

            // now we need to update the order index of all the sibling blocks
            const sortBlocks = this.getBlocksSortedByBlockPosition(parent.blocks, breakpointId);

            // ad the block to the new parent in the correct location
            sortBlocks.splice(orderIndex, 0, block);
            parent.blocks.splice(orderIndex, 0, block);

            // now we will set all the siblings new orderIndex
            sortBlocks.forEach((siblingBlocks, order) => {
                this.setOrderIndexForBlock( order, siblingBlocks, breakpoint);
            });

            if (oldParent && oldParent.referenceId !== parent.referenceId) {
                const oldSortBlocks = this.getBlocksSortedByBlockPosition(oldParent.blocks, breakpointId);

                // update the old parent order indexes ass well
                oldSortBlocks.forEach((oldSiblingBlocks, order) => {
                    this.setOrderIndexForBlock( order, oldSiblingBlocks, breakpoint);
                });
            }
        },
        getBlocksSortedByBlockPosition(blocks, breakpointId) {
            // now we need to update the order index of all the sibling blocks
            const sortBlocks = [...blocks];

            sortBlocks.sort((oldBlock, newBlock) => {
                const oldBlockPosition = this.getBlockPositioning(oldBlock.referenceId, breakpointId);
                const newBlockPosition = this.getBlockPositioning(newBlock.referenceId, breakpointId);

                if (oldBlockPosition.orderIndex < newBlockPosition.orderIndex) {
                    return -1;
                }

                if (oldBlockPosition.orderIndex > newBlockPosition.orderIndex) {
                    return +1;
                }

                return 0;
            });

            return sortBlocks;
        },
        async getRequiredParentBlock(type) {
            const blockTypes = await this.dataProvider.getBlockTypes();

            const parentBlockType = blockTypes.find(blockType => blockType.type === type);
            return this.dataProvider.getNewBlockByType(parentBlockType);
        },
        /**
         * Set the order index for a specific block.
         * We do this by updating the block positioning in the current breakpoint
         * @param orderIndex
         * @param block
         * @param breakpoint
         */
        setOrderIndexForBlock(orderIndex, block, breakpoint) {
            const positioning = breakpoint.blockPositioning.find(blockPositioning => blockPositioning.referenceId === block.referenceId);

            if (breakpoint.isOriginal || positioning.orderIndex === null) {
                this.breakpoints.forEach(breakpointItem => {
                    let positioning = breakpointItem.blockPositioning.find(blockPositioning => blockPositioning.referenceId === block.referenceId)
                    positioning.orderIndex = orderIndex;
                });
            }

            positioning.orderIndex = orderIndex;

            this.triggerDataProviderEvent('orderChanged');
            this.triggerDataProviderEvent('propertyChanged');
        },
        /**
         * @param properties
         * @param breakpointId
         */
        blockPropertyChanged(properties, breakpointId) {
            const referenceId = properties.referenceId;
            const block = BlockHelper.getBlock(this.blocks, referenceId);
            delete properties['referenceId'];

            Object.keys(properties).forEach(key => {
                block[key] = properties[key];
            });

            this.triggerDataProviderEvent('propertyChanged');
        },
        /**
         * Block positioning properties have changed
         * @param properties
         * @param breakpointId
         */
        blockPositioningChanged(properties, breakpointId) {
            const breakpoint = this.breakpoints.find(breakpoint => breakpoint.id === breakpointId);
            const referenceId = properties.referenceId
            delete properties['referenceId'];

            // Check if we support changing the offset
            if (properties.offset && !this.featureHandler.featureSupported('breakpoints-specific-offset')) {
                return;
            }

            // check if we support full width for specific breakpoints
            if(Object.hasOwn(properties, 'fullWidth') && !properties['allBreakpoints']
                && !breakpoint.isOriginal && !this.featureHandler.featureSupported('breakpoints-specific-full-width')) {
                return;
            }

            // check if we support full width for specific breakpoints
            if(Object.hasOwn(properties, 'hidden') && !this.featureHandler.featureSupported('breakpoints-specific-visibility')) {
                return;
            }

            // Check if we need to do this action for all breakpoints
            if (properties['allBreakpoints']) {
                delete properties['allBreakpoints'];
                this.breakpoints.forEach(breakpoint => {
                    this.setBlockPositioningProperty(referenceId, properties, breakpoint.id);
                });
                return;
            }

            this.setBlockPositioningProperty(referenceId, properties, breakpointId);
        },
        /**
         * Update the property
         * We do this by updating the block positioning in the current breakpoint
         * The blueprintDataTranslator will handle the rest
         * @param $event
         * @param breakpointId
         */
        setBlockPositioningProperty(referenceId, properties, breakpointId) {
            const breakpoint = this.breakpoints.find(breakpoint => breakpoint.id === breakpointId);
            const positioning = breakpoint.blockPositioning.find(blockPositioning => blockPositioning.referenceId === referenceId);

            // we will set all the properties
            Object.keys(properties).forEach(key => {
                positioning[key] = properties[key];
            });

            this.triggerDataProviderEvent('propertyChanged');
        },
        /**
         * Check if the dataprovider has an event listiner for the current event
         * if so trigger it
         * @param $eventName
         */
        async triggerDataProviderEvent($eventName) {
            const $callback = this.dataProvider.getEventListeners()[$eventName];

            if($callback) {
                return await $callback(this.breakpoints, this.blocks, this.visibility);
            }
        },
        /**
         * We show the condition form for the specific breakpoint
         * @param $breakpoint
         */
        showConditionsForBreakpoint(breakpoint) {
            this.$router.push({name: 'blueprint-condition-form', params: {breakpointId: breakpoint.id}});
        },
        /**
         *
         */
        async saveBlueprint() {
            this.loading = true;
            const result = await this.triggerDataProviderEvent('save');

            this.breakpoints = this.dataProvider.getBreakpoints();
            this.blocks = this.dataProvider.getBlocks();
            this.grid = this.dataProvider.getGrid();
            this.visibility = this.dataProvider.getVisibility();
            this.blueprintDataStartState = JSON.parse(JSON.stringify(this.blueprintData));
            this.$handleActions(result.todos, this)
            this.loading = false;
        },
        /**
         * Activate or deactive the preview mode
         * @param previewMode bool
         */
        setPreviewMode(previewMode) {
            this.previewMode = previewMode;
        },
        /**
         * Toggle guides of root level
         */
        toggleGuides() {
            this.showGuides = ! this.showGuides;
        },
        /**
         * Hide or show the current hidden elements
         */
        toggleHiddenElements() {
            this.showHiddenElements = ! this.showHiddenElements;
        },
        /**
         * Get the block positioning for the given referenceId and breakpointId
         * @param referenceId
         * @param breakpointId
         * @returns {*}
         */
        getBlockPositioning(referenceId, breakpointId) {
            const breakpoint = this.breakpoints.find(breakpoint => breakpoint.id === breakpointId);
            return breakpoint.blockPositioning.find(blockPositioning => blockPositioning.referenceId === referenceId);
        },
        /**
         * Duplicate a block
         * @param referenceId
         */
        duplicateBlock(referenceId) {
            const block =  BlockHelper.getBlock(this.blocks, referenceId);
            const blockParent =  BlockHelper.getParentBlock(this, referenceId);
            const duplicatedBlock = {...block};

            duplicatedBlock.id = null;
            duplicatedBlock.name = null;
            duplicatedBlock.blocks = [];
            duplicatedBlock.referenceId = this.dataProvider.getNewReferenceId();

            blockParent.blocks.push(duplicatedBlock);

            this.duplicateBlockPositions(block, duplicatedBlock, true);

            this.duplicateBlockChildren(block, duplicatedBlock);
        },
        /**
         * Duplicate children
         * @param block
         * @param duplicatedBlock
         */
        duplicateBlockChildren(block, duplicatedBlock){
            let children = [];
            block.blocks.forEach((childBlock, key) => {
                let duplicatedChildBlock = {...childBlock};
                duplicatedChildBlock.id = null;
                duplicatedChildBlock.name = null;
                duplicatedBlock.blocks = [];
                duplicatedChildBlock.referenceId = this.dataProvider.getNewReferenceId();
                children.push(duplicatedChildBlock);

                this.duplicateBlockPositions(childBlock, duplicatedChildBlock);

                this.duplicateBlockChildren(childBlock, duplicatedChildBlock)
            });

            duplicatedBlock.blocks = children
        },
        /**
         * Duplicate the block positions
         * @param block
         * @param duplicatedBlock
         */
        duplicateBlockPositions(block, duplicatedBlock, updateOrderIndex) {
            this.breakpoints.forEach(breakpoint => {
                let blockPositioning = this.getBlockPositioning(block.referenceId, breakpoint.id);
                let duplicatedBlockPositioning = {...blockPositioning};

                duplicatedBlockPositioning.blockId = null;
                duplicatedBlockPositioning.referenceId = duplicatedBlock.referenceId;

                if (updateOrderIndex) {
                    duplicatedBlockPositioning.orderIndex = blockPositioning.orderIndex + 1;
                }

                breakpoint.blockPositioning.push(duplicatedBlockPositioning);
            });
        },
        /**
         * Overide the settings for the block with the geiven referenceId
         * @param $event
         */
        saveSettings($event) {
            let block = BlockHelper.getBlock(this.blocks, $event.referenceId);
            // we force the reactivity
            this.$set(block, 'settings', $event.settings);
        },
        /**
         * Show the feature message modal
         * @param message
         */
        showFeatureMessage(message) {
            this.featureModalOpen = true;
            this.featureMessage = message ? message : this.$t('Deze feature wordt niet ondersteund');
        },
        /**
         * Close the feature message modal
         */
        closeFeatureModal() {
            this.featureModalOpen = false;
            this.featureMessage = null;
        },
        /**
         * Notiffy the dataprovider we want to save te data object
         * @param $event
         * @returns {Promise<void>}
         */
        async saveDataObject($event) {
            this.loading = true;
            await this.dataProvider.getBlockSettings();
            this.loading = false;
        },
        /**
         * Check if we need to show the confirm modal
         * @param to
         * @param from
         * @param next
         * @returns {Promise<boolean>}
         */
        async checkShowConfirm(to, from, next) {
            // to.matched.some(route => route.name === 'blueprint');

            return !this.$deepEqual(this.blueprintDataStartState, this.blueprintData) && !to.matched.some(route => route.name === 'blueprint');
        },
        /**
         * Check if the given parent is an area or any if its parents is an area
         * @param block
         * @returns {boolean|*}
         */
        checkInArea(block) {
            if(block.type === 'block-area' || block.type === 'block-legacy-area') {
                return true;
            }

            const parent = BlockHelper.getParentBlock(block);

            if (! parent) {
                return false;
            }

            return this.checkInArea(parent);
        },
        /**
         * Get the width for the given breakpoint
         * @param breakpoint
         * @returns {string}
         */
        getBreakpointWidth(breakpoint) {
            return BreakpointHelper.getBreakpointWidth(breakpoint)
        }
    },
    async created() {
        this.dataProvider = new this.dataProviderClass(this.blueprintId, this.queryParams);
        this.loading = true;
        await this.dataProvider.fetchBlueprintData();
        await this.dataProvider.fetchInterfaceData();
        this.loading = false;

        this.breakpoints = this.dataProvider.getBreakpoints();
        this.blocks = this.dataProvider.getBlocks();
        this.visibility = this.dataProvider.getVisibility();
        this.featureHandler = this.dataProvider.getFeatureHandler(this.showFeatureMessage);
        this.grid = this.dataProvider.getGrid();
        this.originalBreakpoint = this.dataProvider.getOriginalBreakpoint();

        // set the original breakpoint as the active breakpoint
        this.activeBreakpointId = this.originalBreakpoint.id;

        // we need to get the initial state so we deep clone the value
        this.blueprintDataStartState = JSON.parse(JSON.stringify(this.blueprintData));
        this.$confirmHandler.setShowConfirmCallback(this.checkShowConfirm);
        this.$confirmHandler.setConfirmMessage(this.$t('Ben je zeker dat je verder wilt gaan zonder te bewaren? De wijzigingen die je hebt gedaan, zullen verloren gaan.'));
    },
}, 'blueprints/bp-blueprint.html');