export default class BlueprintDataTranslator {
    constructor(breakpoints, blocks) {
        this.breakpoints = breakpoints;
        this.blocks = blocks;
        this.getBlocksForBreakpoint = this.getBlocksForBreakpoint.bind(this);
        this.translateBlocks = this.translateBlocks.bind(this);
        this.translateBlock = this.translateBlock.bind(this);
        this.translate = this.translate.bind(this);
    }

    /**
     *
     * @param breakpoint
     * @returns {*[]}
     */
    getBlocksForBreakpoint(breakpoint) {
        return this.translateBlocks(this.blocks, breakpoint);
    }

    /**
     *
     * @param blocks
     * @param breakpoint
     * @returns {*[]}
     */
    translateBlocks(blocks, breakpoint) {
        let out = [];

        blocks.forEach(block => {
            const blockPosition = breakpoint.blockPositioning.find(position => position.referenceId === block.referenceId);

            if (!blockPosition || blockPosition.orderIndex == null) {
                return;
            }

            if(out[blockPosition.orderIndex] !== undefined) {
                out.splice(blockPosition.orderIndex, 0, this.translateBlock(block, breakpoint, blockPosition));
            } else {
                out[blockPosition.orderIndex] = this.translateBlock(block, breakpoint, blockPosition);
            }

        });

        return out;
    }

    /**
     *
     * @param block
     * @param breakpoint
     * @param blockPosition
     * @returns {{colspan, fullWidth, minColspan: (number|*), offset, areChildrenAllowed: (boolean|*), orderIndex, id, endOfRow, title, type, hidden}}
     */
    translateBlock(block, breakpoint, blockPosition) {
        let out = {
            id: block.id,
            referenceId: block.referenceId,
            name: block.name,
            nameDefault: block.nameDefault,
            type: block.type,
            locks: block.locks ,
            requiredParent: block.requiredParent,
            orderIndex: blockPosition.orderIndex,
            minColspan: block.minColspan,
            areChildrenAllowed: block.areChildrenAllowed,
            colspan: blockPosition.colspan,
            offset: blockPosition.offset,
            fullWidth: blockPosition.fullWidth,
            endOfRow: block.endOfRow,
            hidden: blockPosition.hidden,
            settings: block.settings,
            alignment: blockPosition.alignment,
        };

        if (block.blocks && block.blocks.length) {
            out['blocks'] = this.translateBlocks(block.blocks, breakpoint)
        }

        return out;
    }

    /**
     *
     * @returns {*[]}
     */
    translate() {
        let out = [];

        this.breakpoints.forEach((breakpoint, key) => {
            // first we need to calculate the order index for all blockPositions in its current breakpoint

            out[key] = {
                ...breakpoint,
                blocks: this.getBlocksForBreakpoint(breakpoint)
            }

            delete out[key].blockPositioning;
        });

        return out;
    }
}