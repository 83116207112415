export default class HeaderHandler {
    constructor(headers) {
        this.setHeaders(headers);
        this.vue = window.AppKit.getApp();
    }

    /**
     * get headers
     * @returns {*}
     */
    getHeaders() {
        return this.headers;
    }

    /**
     * Set headers
     * @param headers
     */
    setHeaders(headers) {
        this.headers = headers;
    }

    /**
     * Get the meta data form url
     * @param headersId
     * @param blueprintId
     * @returns {string}
     */
    getHeadersFormUrl(headersId, blueprintId, settings) {
        const url = new URL(this.vue.$getSetting('paths.baseBlueprint') + '/headers/form/' + headersId + '/' + blueprintId);

        if(settings) {
            url.searchParams.append('settings', settings);
        }

        return url.toString();
    }

    /**
     * Get the header save form url
     * @param headersId
     * @param blueprintId
     * @returns {string}
     */
    getSaveHeadersFormUrl(headersId, blueprintId) {
        const url = new URL(this.vue.$getSetting('paths.baseBlueprint') + '/headers/form/' + headersId + '/' + blueprintId + '/post');
        return url.toString();
    }

    /**
     * Get all the supported headers options for a blueprint
     * @param bluerpintId
     * @returns {Promise<[{name: string, description: string, id: string},{name: string, description: string, id: string},{name: string, description: string, id: string}]>}
     */
    async getSupportedHeaders() {
        const url = await this.vue.$getSetting('paths.baseBlueprint') + '/http-headers/supported';
        return await this.vue.$get(url);
    }
}