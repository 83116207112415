import ActiveDraggableBlockMixin from "../../../mixins/ActiveDraggableBlockMixin";
import ActiveDropzoneMixin from "../../../mixins/ActiveDropzoneMixin";
import EditorEventMixin from "../../../mixins/EditorEventMixin";

Vue.asyncComponent('bp-preview', {
    mixins: [ActiveDraggableBlockMixin, ActiveDropzoneMixin, EditorEventMixin],
    data() {
        return {
            previewIframe: null,
        }
    },
    props: {
        blocks: {
            type: Array,
            required: true,
        },
        breakpoint: {
            type: Object,
            required: true,
        },
        dataProvider: {
            type: Object,
            required: true,
        },
        forceGuides: {
            type: Boolean,
            required: false,
            default: false
        },
        grid: {
            type: Object,
            required: true,
        },
        activeBreakpointId: {
            type: String,
            required: true,
        }
    },
    methods: {
        /**
         * Make sure we show the content of the iframe without a scroll bar
         */
        setIframeHeight() {
            if(this.$refs.iframe.height !== this.$refs.iframe.contentWindow.document.body.scrollHeight+ "px") {
                // first we make sure the iframe is no bigger than the content otherwise the height will be the current iframe height
                this.$refs.iframe.height = 0;
                // we always add 1px extra for rounding issues
                this.$refs.iframe.height = this.$refs.iframe.contentWindow.document.body.scrollHeight + 1 + "px";
            }
        },
        /**
         * We trigger a refresh of the iframe with the new settings
         * @param $event
         * @param callback
         */
        refresh($event, callback) {
            if ($event && callback) {
                // first we trigger the callback so ower data is up to date with the newest state
                callback($event);
            }

            // then we trigger a refresh of the preview
            this.$refs.iframe.contentWindow.location.reload();
        }
    },
    mounted() {
        this.previewIframe = this.$refs.iframe;
        document.addEventListener('resize', this.setIframeHeight);
    }
}, 'blueprints/editor/preview/bp-preview.html');