Vue.asyncComponent('bp-active-headers',{
    props: {
        dataProvider: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            headerHandler: null,
            headers: [],
            loading: false
        }
    },
    computed: {
        /**
         * Blueprint id out of the url
         * @returns {any}
         */
        blueprintId() {
            return this.$route.params.blueprintId;
        },
    },
    methods: {
        /**
         * fetch the active headers buttons
         * @returns {Promise<void>}
         */
        async getHeaders() {
            this.loading = true;
            this.headerHandler = await this.dataProvider.getHeaderHandler();
            this.headers = this.headerHandler.getHeaders();
            this.loading = false;
        },
        /**
         * Open the headers selection
         */
        selectHeaders() {
            // Check if metadata is supported
            if (! this.dataProvider.getFeatureHandler().featureSupported('blueprint-headers')) {
                return;
            }

            this.$router.push({name: 'headers-selection', query: this.$route.query});
        },
        /**
         * Set the new array of active headers
         * @param headers
         */
        updateHeaders(headers) {
            this.headerHandler.setHeaders(headers)
            this.getHeaders();
        },
        /**
         * Remove a header item
         * @param headerItemToDelete
         */
        removeHeaderItem(headerItemToDelete) {
            // Check if metadata is supported
            if (! this.dataProvider.getFeatureHandler().featureSupported('blueprint-headers')) {
                return;
            }

            const indexToDelete = this.headers.findIndex(headerItem => headerItem.id === headerItemToDelete.id);
            this.headers.splice(indexToDelete, 1);

            this.headerHandler.setHeaders(this.headers);
        }
    },
    created() {
        this.getHeaders();
    }
}, 'blueprints/headers/bp-active-headers.html');