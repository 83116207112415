Vue.asyncComponent('bp-block-name', {
    data: function() {
        return {
            editTitle: false,
            nameLocal: this.name ? this.name : this.nameDefault,
        }
    },
    props: {
        name: {
            type: String,
        },
        nameDefault: {
            type: String,
            required: true,
        },
        areChildrenAllowed: {
            type: Boolean,
        },
    },
    computed: {
        /**
         * Here we check if a custom name is set otherwise we return the default name
         * @returns {string|*|(function(): string)}
         */
        currentName() {
            if ( this.name ) {
                return this.name;
            }

            return this.nameDefault;
        },
    },
    methods: {
        /**
         * We want to start editing the name
         */
        startEditTitle() {
            this.editTitle = true;

            // we want to focus on the input field when visisble
            this.$nextTick(() => {
                this.$refs.nameField.focus();
            });
        },
        /**
         * Set a new custom name for the element
         */
        setNewName() {
            this.editTitle = false;
            this.$emit('changed', this.nameLocal);
        },
    }
}, 'blueprints/editor/bp-block-name.html');