const activeDraggableBlockMixin = {
    data: () => {
        return {
            activeBlockLocal: null,
        }
    },
    model: {
        prop: 'activeBlock',
        event: 'setActiveBlock'
    },
    props: {
        activeBlock: {
            required: false,
        },
    },
    watch: {
        activeBlock: function(newVal, oldVal) {
            this.activeBlockLocal = newVal;
        },
        activeBlockLocal: function(newVal, oldVal) {
            this.$emit('setActiveBlock', newVal);
        }
    },

    methods: {
        setActiveBlock(block) {
            this.activeBlockLocal = block;

            // when the block is null we have stopped dragging
            if (block === null) {
                this.stopDragging()
            }
        },
        getActiveBlock() {
            return this.activeBlockLocal;
        },
        stopDragging() {
            this.$emit('stopDragging');
        }
    },
    created() {
        this.activeBlockLocal = this.activeBlock;
    }
};

export default activeDraggableBlockMixin;