Vue.asyncComponent('bp-blocks-wrapper', {
    props: {
        parentBlockId: {
            type: String,
            required: false,
        },
        blocks: {
            type: Array,
            required: true,
        },
        previewMode: {
            type: Boolean,
            required: false,
            default: false
        },
        previewIframe: {
            type: HTMLIFrameElement,
        },
    },
    computed: {
        /**
         * We seperate the blocks in seperat    e containers and rows
         */
        containers() {
            // we only render containers if we are at the highest level
            if(this.parentBlockId !== undefined) {
                return [];
            }

            let containers = [{
                rows: [[]],
                fullWidth: false
            }];

            let containerKey = 0;
            let rowKey = 0;

            this.blocks.forEach(block => {
                // if this item is full width and there are'nt any other rows/blocks we will make the current container full width and push this item
                if (block.fullWidth && containers[containerKey].rows.length === 1 && containers[containerKey].rows[rowKey].length === 0) {
                    containers[containerKey].fullWidth = true;
                    containers[containerKey].rows[rowKey].push(block);

                    // add a new container for the next items
                    containers.push({
                        rows: [[]],
                        fullWidth: false
                    });
                    containerKey++;
                    rowKey = 0;

                // if this item is full width and there are other rows/blocks we will make a new container full width and push this item
                } else if(block.fullWidth && (containers[containerKey].rows.length > 1 || containers[containerKey].rows[rowKey].length > 0)) {
                    containers.push({
                        rows: [[]],
                        fullWidth: false
                    });
                    containerKey++;
                    rowKey = 0;

                    containers[containerKey].fullWidth = true;
                    containers[containerKey].rows[rowKey].push(block);

                    containers.push({
                        rows: [[]],
                        fullWidth: false
                    });
                    rowKey = 0;
                    containerKey++;
                // if the block isen't full width we just push the block to the current container
                } else {
                    containers[containerKey].rows[rowKey].push(block);
                }

                // if this item is end fo row we add a new "row" and push the next items in it
                if (block.endOfRow) {
                    containers[containerKey].rows.push([]);
                    rowKey++;
                }
            });

            return containers;
        },
        /**
         * Get the blocks separeted in rows
         * @returns {*[][]}
         */
        blockRows() {
            // we only render rows without containers if we are not at the highest level
            if(this.parentBlockId === undefined) {
                return [];
            }

            let rows = [[]];
            let rowKey = 0;

            this.blocks.forEach(block => {
                rows[rowKey].push(block);

                // if this item is end fo row we add a new "row" and push the next items in it
                if(block.endOfRow) {
                    rows.push([]);
                    rowKey++;
                }
            });

            return rows;
        }
    },
}, 'blueprints/editor/grid/bp-blocks-wrapper.html');