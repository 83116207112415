Vue.asyncComponent('bp-block-actions', {
    data() {
        return {
            menuOpen: false,
            fullWidthModalVisible: false,
            messageModalOpen: false,
            message: null
        }
    },
    props: {
        hidden: {
            type: Boolean,
            required: false,
            default: false
        },
        endOfRow: {
            type: Boolean,
            required: false,
            default: false
        },
        fullWidth: {
            type: Boolean,
            required: false,
            default: false
        },
        previewMode: {
            type:  Boolean,
            required: false,
            default: false
        },
        areChildrenAllowed: {
            type:  Boolean,
            required: false,
            default: false
        },
        showGuides: {
            type:  Boolean,
            required: false,
            default: false
        },
        showDivideChildrenEqually: {
            type: Boolean,
            required: false,
            default: false
        },
        showAlignmentOptions: {
            type: Boolean,
            required: false,
            default: false
        },
        locks: {
            type:  Array,
            required: false,
            default: () => []
        },
        canEdit: {
            type:  Boolean,
            required: false,
            default: true
        },
        canDelete: {
            type: Boolean,
            required: false,
            default: true
        },
        canChangeLayout: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    computed: {
        /**
         * get the change layout message
         * @returns {*}
         */
        canChangeLayoutMessage() {
            return this.this.getLockByAction('layout')?.message;
        },
        /**
         * get the edit message
         * @returns {*}
         */
        canEditMessage() {
            return this.this.getLockByAction('edit')?.message;
        },
        /**
         * Get the delete message
         * @returns {*}
         */
        canDeleteMessage() {
            return this.getLockByAction('delete')?.message;
        }
    },
    methods: {
        /**
         * Close the current menu and emit an event
         * @param $eventName
         * @param value
         */
        closeMenuAndEmit($eventName, value) {
            this.fullWidthModalVisible = false
            this.menuOpen = false;
            this.$emit($eventName, value);
        },
        /**
         * Show message
         * @param message
         */
        showMessage(message) {
            if (! message) {
                return;
            }
            this.messageModalOpen = true;
            this.message = message;
        },
        /**
         * Reset message
         */
        closeMessage() {
            this.messageModalOpen = false;
            this.message = null;
        },
        /**
         * We get the lock by the given action or if a lock has action all
         * @param action
         * @returns {*}
         */
        getLockByAction(action) {
            return this.locks.find(lock => lock.isLocked && (lock.action.includes(action) || lock.action.includes('all')));
        },
        /**
         * Show the full width modal
         * Here the user can decide to toggle full width for this block in all breakpoints
         * or only the current breakpoint
         */
        showFullWidthModal() {
            this.fullWidthModalVisible = true;
        }
    }
}, 'blueprints/editor/bp-block-actions.html');