import DivideChildrenHelper from "./DivideChildrenHelper";

/**
 * Helper methods for blocks
 */
export default class BlockHelper {

    /**
     * Get a block in an array of blocks by reference id
     * @param blocks
     * @param referenceId
     * @returns {null}
     */
    static getBlock(blocks, referenceId) {
        let result = null;

        // get the block by id recursive
        blocks.forEach(block => {
            if(block.referenceId === referenceId) {
                result = block;
            }

            if(block.blocks.length) {
                let childResult = this.getBlock(block.blocks, referenceId);
                if(childResult) {
                    result = childResult;
                }
            }
        });

        return result;
    }

    /**
     * Get the parent block by reference id of the child block
     * @param parent
     * @param childReferenceId
     * @returns {null}
     */
    static getParentBlock(parent, childReferenceId) {
        let result = null;

        // get the block by id recursive
        parent.blocks.forEach(block => {
            if(block.referenceId === childReferenceId) {
                result = parent;
            }

            if(block.blocks.length) {
                let childResult = this.getParentBlock(block, childReferenceId);
                if(childResult) {
                    result = childResult;
                }
            }
        });

        return result;
    }

    /**
     * Calculate the cols that have been taken until the end fo this current block
     * @param blocks
     * @param block
     * @param maxCols
     * @returns {number}
     */
    static spaceTakenForItemsUntil(blocks, block, maxCols) {
        const index = blocks.indexOf(block);
        // we get all the blocks before the active item and include the active item ass well
        let totalSpace = 0;

        // now we calculate which space they take up
        blocks.slice(0, index + 1).forEach((block) => {
            const spaceToAdd = block.colspan + block.offset;

            // if
            if(totalSpace + spaceToAdd > maxCols) {
                totalSpace = 0;
            }

            totalSpace += spaceToAdd;
        });

        return totalSpace
    }

    /**
     * Divide Children equally
     * We will try to provide the best ways to divide the children equally for each row
     * This function will return an object width block referenceIds and the calculated colspans
     * @param blocks
     * @param maxCols
     * @param maxPerRow
     * @param greedy
     * @returns {{}}
     */
    static divideChildrenEqually(blocks, maxCols, maxPerRow = 4, greedy = 2) {
        return (new DivideChildrenHelper(blocks, maxCols, maxPerRow, greedy)).getColspans();
    }
}