Vue.asyncComponent('bp-blueprint-settings', {
    props: {
        dataProvider: {
            type: Object,
            required: true
        }
    },
    computed: {
        activeTab() {
            return this.$route.params.activeTab;
        }
    },
    methods: {
        setActiveTab(activeTab) {
            this.$router.push({
                name: 'blueprint-settings',
                query: this.$route.query,
                params: { activeTab: activeTab }
            });
        },
        /**
         * Close the stacked window by going back
         */
        close() {
            this.$router.push({name: 'blueprint',params:{blueprintId: this.$route.params.blueprintId}, query: this.$route.query});
        },
    }

}, 'blueprints/settings/bp-blueprint-settings.html');