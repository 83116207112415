Vue.asyncComponent('bp-active-metadata',{
    props: {
        dataProvider: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            metadataHandler: null,
            metadata: [],
            loading: false
        }
    },
    computed: {
        /**
         * Blueprint id out of the url
         * @returns {any}
         */
        blueprintId() {
            return this.$route.params.blueprintId;
        },
    },
    methods: {
        /**
         * fetch the active metadata buttons
         * @returns {Promise<void>}
         */
        async getMetadata() {
            this.loading = true;
            this.metadataHandler = await this.dataProvider.getMetadataHandler();
            this.metadata = this.metadataHandler.getMetadata();
            this.loading = false;
        },
        /**
         * Open the metadata selection
         */
        selectMetadata() {
            // Check if metadata is supported
            if (! this.dataProvider.getFeatureHandler().featureSupported('blueprint-metadata')) {
                return;
            }

            this.$router.push({name: 'metadata-selection', query: this.$route.query});
        },
        /**
         * Go to metadata form
         * @param id
         */
        goToMetadataForm(metadata) {
            // Check if metadata is supported
            if (! this.dataProvider.getFeatureHandler().featureSupported('blueprint-metadata')) {
                return;
            }

            this.$router.push({
                name: 'metadata-form',
                params: {id: metadata.id},
                query: {
                    verticalId: this.$route.query.verticalId,
                    settings: JSON.stringify(metadata.settings),
                }
            });
        },
        /**
         * Set the new array of active metadata
         * @param metadata
         */
        updateMetadata(metaData) {
            this.metadataHandler.setMetadata(metaData)
            this.getMetadata();
        },
        /**
         * Remove a metadata item
         * @param metadataItemToRemove
         */
        removeMetadataItem(metadataItemToRemove) {
            // Check if metadata is supported
            if (! this.dataProvider.getFeatureHandler().featureSupported('blueprint-headers')) {
                return;
            }

            const indexToDelete = this.metadata.findIndex(metadataItem => metadataItem.id === metadataItemToRemove.id);
            this.metadata.splice(indexToDelete, 1);

            this.metadataHandler.setMetadata(this.metadata)
        },
        /**
         * Update the metadata item
         * @param id
         * @param settings
         * @returns {Promise<void>}
         */
        updateMetadataItem({id, settings}) {
            // get the full object
            const metadata = this.metadata.find(metadata => metadata.id === id);
            // ad the new settings
            metadata.settings = settings;
            this.updateMetadata(this.metadata);
        },
    },
    created() {
        this.getMetadata();
    }
}, 'blueprints/meta/bp-active-metadata.html');