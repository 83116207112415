Vue.asyncComponent('bp-blueprint-top-bar', {
    data() {
        return {
            featureHandler: null,
            openPreviewScaleOptions: false,
            scaleOptions: [
                {
                    label: 'Previewing at 200%',
                    value: '200%'
                },
                {
                    label: 'Previewing at 100%',
                    value: '100%'
                },
                {
                    label: 'Previewing at 50%',
                    value: '50%'
                },
            ]
        }
    },
    props: {
        blueprintId: {
            type: String,
            required: true,
        },
        verticalId: {
            type: String,
            required: true,
        },
        previewMode: {
            type: Boolean,
            required: false,
        },
        previewScale: {
            type: String,
            required: false,
            default: '100%'
        },
        showPreviewToggle: {
            type: Boolean,
            default: false
        },
        showPreviewScale: {
            type: Boolean,
            default: false
        },
        visibility: {
            type: Object,
        },
        dataProvider: {
            type: Object,
            default: false
        }
    },
    computed: {
        localPreviewMode: {
            get() {
                return this.previewMode;
            },
            set(e) {
                return this.$emit('changePreviewMode', e);
            }
        },
        localPreviewScaleLabel: {
            get() {
                return this.scaleOptions.find( option => option.value === this.previewScale)?.label;
            },
            set(e) {
                return this.$emit('changePreviewScale', e);
            }
        },
        /**
         * Show the page settings or not
         * @returns {*}
         */
        showPageSettings() {
            return (
                    this.showBlueprintCopy ||
                    this.showBlueprintShareLink ||
                    this.featureHandler.featureSupported('blueprint-header', true) ||
                    this.featureHandler.featureSupported('blueprint-metadata', true)
            ) && !this.showPreviewScale;
        },
        /**
         * Show blueprint copy
         * @returns {Boolean|*}
         */
        showBlueprintCopy() {
            return this.featureHandler.featureSupported('blueprint-copy', true);
        },
        /**
         * Show blueprint share link
         * @returns {Boolean|*}
         */
        showBlueprintShareLink() {
            return this.featureHandler.featureSupported('blueprint-share-link', true);
        },
        /**
         * Get the blueprint title
         * @returns {*}
         */
        blueprintTitle() {
            return this.dataProvider.getTitle();
        }
    },
    methods: {
        /**
         * Set the scale we want the preview to be renderd
         * @param value
         */
        setPreviewScale(value) {
            this.openPreviewScaleOptions = false;
            this.$emit('changePreviewScale', value);
        },
        /**
         * show the modal where we can copy this page to another vertical
         */
        showCopyToVertical() {
            console.log('@todo: show copy to vertical modal');
        },
        /**
         * trigger the blueprint settings route
         */
        showSettings() {
            this.$router.push({
                name: 'blueprint-settings',
                query: this.$route.query,
                params: { activeTab: 'metadata' }
            });
        },
        /**
         * Get the list url and navigate to the route
         */
        async goToList() {
            const listUrl = await this.dataProvider.getListUrl();

            this.$handleRoute(listUrl, this.target);
        }
    },
    created() {
        this.featureHandler = this.dataProvider.getFeatureHandler();
    }
}, 'blueprints/bp-blueprint-top-bar.html');